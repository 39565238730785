import { ThemeProvider } from '@dreipol/pusch-components';
import { MediaQueryConfigContext } from '@dreipol/t3-react-media';
import { ScrollLockProvider } from '@dreipol/t3-react-utils';
import localFont from '@next/font/local';
import { appWithTranslation } from 'next-i18next';
import { AppProps } from 'next/app';
import { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import TagManager from 'react-gtm-module';
import { BREAKPOINTS } from '../config';
import i18n from '../next-i18next.config';
import '../src/styles/globals.scss';
import { useRouter } from 'next/dist/client/router';
import ArticleFilterContextProvider from 'src/context/article-filter-context';

const localFonts = localFont({
  src: [
    {
      path: '../public/assets/fonts/MuseoSans500.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../public/assets/fonts/MuseoSans500Italic.woff2',
      weight: '500',
      style: 'italic',
    },
    {
      path: '../public/assets/fonts/MuseoSans700.woff2',
      weight: '700',
      style: 'normal',
    },
  ],
  display: 'swap',
});

export function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) {
  const router = useRouter();
  useEffect(() => {
    //do not initialize GTM on preview links
    if (router.asPath.includes('preview')) return;
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID ?? '' });
  }, []);
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? ''}
      scriptProps={{
        async: false,
        defer: true,
        appendTo: 'body',
        nonce: undefined,
      }}
    >
      <ArticleFilterContextProvider>
        <MediaQueryConfigContext.Provider
          value={{
            breakpoints: BREAKPOINTS,
          }}
        >
          <ScrollLockProvider>
            <ThemeProvider
              theme={{
                font: {
                  'font-family': localFonts.style.fontFamily,
                  'bold-weight': 700,
                  'regular-weight': 500,
                },
              }}
            >
              <Component {...pageProps} />
              <div id={'modalPortal'} />
              <div id={'tooltipPortal'} />
            </ThemeProvider>
          </ScrollLockProvider>
          </MediaQueryConfigContext.Provider>
        </ArticleFilterContextProvider>
    </GoogleReCaptchaProvider>
  );
}

export default appWithTranslation(App, { i18n: i18n.i18n });
