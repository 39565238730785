module.exports.FALLBACK_LOCALE = 'de';
module.exports.BREAKPOINTS = {
  xs: '(max-width: 575px)',
  sm: '(min-width: 576px) and (max-width: 767px)',
  md: '(min-width: 768px) and (max-width: 991px)',
  lg: '(min-width: 992px) and (max-width: 1199px)',
  xl: '(min-width: 1200px) and (max-width: 1359px)',
  xxl: '(min-width: 1360px)',
};
module.exports.AUTHOR_ROUTE_PREFIX = {
  de: 'autor',
  fr: 'auteur'
}
module.exports.ARTICLE_ROUTE_PREFIX = {
  de: 'artikel',
  fr: 'article'
}
module.exports.PRACTICE_MAP_SLUG = {
  de: 'praxisbeispiele',
  fr: 'carte-interactive'
}
