import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

export type ArticleFilterContextType = {
  activeFilter: string[] | null;
  searchParam: string | null;
  activeFilterPracticeMap: string[] | null;
  scrollingPos: number | null;
  setActiveFilter: Dispatch<SetStateAction<string[] | null>>;
  setActiveFilterPracticeMap: Dispatch<SetStateAction<string[] | null>>;
  setSearchParam: Dispatch<SetStateAction<string>>;
  setScrollingPos: Dispatch<SetStateAction<number | null>>;
};

export const ArticleFilterContext =
  createContext<ArticleFilterContextType | null>(null);

export const useArticleFilterContext = () => useContext(ArticleFilterContext);

const ArticleFilterContextProvider = ({ children }: PropsWithChildren) => {
  const [activeFilter, setActiveFilter] = useState<string[] | null>(null);
  const [activeFilterPracticeMap, setActiveFilterPracticeMap] = useState<
    string[] | null
  >(null);
  const [searchParam, setSearchParam] = useState('');
  const [scrollingPos, setScrollingPos] = useState<number | null>(null);

  useEffect(() => {
    const storedScrollingPos = sessionStorage.getItem('scrollingPos');
    if (storedScrollingPos) {
      setScrollingPos(JSON.parse(storedScrollingPos));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('scrollingPos', JSON.stringify(scrollingPos));
  }, [scrollingPos]);

  return (
    <ArticleFilterContext.Provider
      value={{
        activeFilter,
        searchParam,
        activeFilterPracticeMap,
        scrollingPos,
        setSearchParam,
        setActiveFilter,
        setActiveFilterPracticeMap,
        setScrollingPos,
      }}
    >
      {children}
    </ArticleFilterContext.Provider>
  );
};

export default ArticleFilterContextProvider;
