const path = require('path');
const { FALLBACK_LOCALE } = require('./config');

module.exports = {
  debug: true,
  i18n: {
    defaultLocale: FALLBACK_LOCALE,
    locales: ['de', 'fr'],
  },
  defaultNs: 'common',
  localePath: path.resolve('./public/locales'),
};
